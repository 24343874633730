/*-----------------------------------------------------------*\
	$HEADER
\*-----------------------------------------------------------*/


*:not(br):not(tr):not(html):not(head):not(meta):not(title)
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
	font-size: 16px
	font-weight: 400
	color: #5E656B
	suavizadoTexto()

.wraper
	padding-top: 30px

.firma
	width: 90%
	max-width: 620px

	td
		&.col1,
		&.col2
			padding-top: 10px
			padding-bottom: 10px
			vertical-align: top

	strong
		font-weight: 600
		color: #393D41


.nombre
	font-size: 18px
	font-weight: 600
	color: #393D41

.cargo
	color: #014792
	font-weight: 500

.col1
	width: 130px
	padding-right: 15px
	border-right: 1px solid #D0D0D0


.col2
	padding-left: 22px


.espacio1
	height: 20px

.espacio2
	height: 9px

.dato-icon
	td
		vertical-align: middle

.icon
	width: 20px
	padding-right: 5px


.col_celular
	width: 210px


.separador1
	padding-bottom: 13px
	border-bottom: 1px solid #D0D0D0


.enlaces
	padding-top: 13px

.enlace1
	text-decoration: none
	color: #014792

	img
		margin-right: 9px


.redes
	padding-left: 15px
	text-align: right


	a
		margin-left: 10px

.texto-inicial
	color: #ebeff2


.color2
	color: #909ca6

// table
.full-content
	background: #ebeff2



.borde-separador
	border-bottom: 1px solid #e6e9eb

p
	margin-top: 0
	margin-bottom: 25px
	font-weight: 400
	line-height: 1.4

a
	text-decoration: none

.negrita
	font-weight: 700
	color: #646c73



/*	^Espacios
-------------------------------------------------------------*/

.espacio-35
	height: 35px
	line-height: @height

.espacio-30
	height: 30px
	line-height: @height

/*	^Fin - Espacios
-------------------------------------------------------------*/







/*	^Titulos
-------------------------------------------------------------*/

.titulo1
	font-size: 33px
	font-weight: 500
	line-height: 1.3
	color: #2c3d4f

/*	^Fin Titulos
-------------------------------------------------------------*/








/*	^Enlaces
-------------------------------------------------------------*/

.enlace
	color: darken($colorBase, 6)

/*	^Fin - Enlaces
-------------------------------------------------------------*/






/*	^Botones
-------------------------------------------------------------*/

.boton
	display: inline-block
	padding: 8px 15px
	border-radius: 5px
	background: $colorBase
	font-size: 18px
	font-weight: 500
	color: #fff

.boton--mediano-verde
	@extend .boton
	padding: 12px 25px
	font-size: 18px

.boton--grande-verde
	@extend .boton
	padding: 12px 25px
	font-size: 22px


/*	^Fin - Botones
-------------------------------------------------------------*/





/*	^Textos
-------------------------------------------------------------*/


.texto-13
	font-size: 13px
	line-height: 1.3

.texto-17
	font-size: 17px
	line-height: 1.3

.texto-19
	font-size: 19px
	line-height: 1.3


/*	^Fin - Textos
-------------------------------------------------------------*/





/*	^Bloques
	Se definen los colores para los bloques
-------------------------------------------------------------*/

.bloque-blanco
	background: #fff

.bloque-grisClaro
	background: #f7fafc


/*	^Fin Bloques
-------------------------------------------------------------*/





/*	^Header
-------------------------------------------------------------*/

.header
	width: 100%


.header__conten-logo
	padding: 20px
	border-radius: 5px 5px 0 0
	background: $color2

/*	^Fin - Header
-------------------------------------------------------------*/








/*	^Contenido
-------------------------------------------------------------*/

.contenido
	width: 100%
	// max-width: 540px
	padding: 0 26px 0 26px

/*	^Fin - Contenido
-------------------------------------------------------------*/



/*	^Tablas
-------------------------------------------------------------*/
.encabezadoTabla
	padding: 10px 20px
	border-radius: 5px 5px 0 0
	background: #e1e4e6
	font-size: 17px
	font-weight: 700
	color: #42474b

.filaTabla
	padding: 15px 20px
	border-left: 1px solid #eceff1
	border-right: 1px solid #eceff1
	border-bottom: 1px solid #eceff1

	&:last-child
		border-left: none

// a
.btn-ingresar
	display: block
	padding: 7px 15px
	border-radius: 5px
	background: #26bfa2
	font-weight: 500
	color: #fff

/*	^Fin - Tablas
-------------------------------------------------------------*/







/*	^Footer
-------------------------------------------------------------*/

.footer
	border-radius: 0 0 5px 5px
	border-top: 1px solid #edf0f2
	background: #f7fafc

.footer__co1
	width: 60%

.footer__co2
	width: 40%

// a
.icono-social
	margin-left: 12px

	&:first-child
		margin-left: 0

// a
.desuscribir
	font-size: 13px
	text-decoration: underline
	color: #909ca6


.footer__foto
	width: 15%
	padding-right: 15px

.img_firma
	width: 60px



/*	^Fin - Footer
-------------------------------------------------------------*/


.ancho
	border-spacing: 0
	border-collapse: 0



.bloque1
	background: #fff

	.contenedor
		width: 90%
		max-width: 548px
		border-spacing: 0
		border-collapse: collapse;
		margin: 0 auto


.encabezado
	height: 70px
	vertical-align: middle


	&.col1
		width: 60%

		img
			width: 139px

	&.col2
		text-align: right

		img
			width: 19px

		a
			padding-left: 15px


.separador
	height: 18px
	background: #e8e8e8
	border-top: 1px solid #cbcbcb


.bloque2

background: #e8e8e8



.contenedor2
	width: 90%
	max-width: 548px
	margin: 0 auto

.saludo
	height: 30px
	padding: 35px 26px 15px 26px
	border-radius: 7px 7px 0 0
	background: #fff
	font-family: $baseFont-family
	font-size: 22px
	font-weight: 300
	vertical-align: middle



.info
	padding: 0 26px 0 26px
	background: #fff
	font-family: $baseFont-family
	font-weight: 300
	font-size: 15px
	color: #626262

	p
		// margin-top: 0
		// margin-bottom: 25px
		font-family: $baseFont-family
		line-height: 21px

.foto
	width: 20%
	padding-top: 15px
	padding-right: 10px

	img
		max-width: 100%
		min-width: 50px





.masinfo
	font-size: 14px
	font-weight: 300
	line-height: 20px
	vertical-align: middle
	color: #7f7f7f

.tel
	width: 180px



.bloque3
	background: #e0e0e0

.bordefinal
	height: 25px
	border-radius: 0 0 7px 7px
	background: #fff

.conexion
	height: 216px
	background: url("https://www.nigma.co/plantilla-email/nigma/imagenes/conexion.jpg") center top
	background-position: center top
	vertical-align: top

.bloque4
	height: 157px
	text-align: center
	vertical-align: top

.bloque4
	img
		width: 241px

.bloque5
	height: 59px
	vertical-align: top
	color: #5d5d5d

	&.der,
	&.iz
		font-size: 14px

	&.der
		text-align: right

	a
		text-decoration: none
		color: #5d5d5d

.accesolink
	display: inline-block
	padding-top: 5px
	padding-bottom: 5px
	padding-left: 10px
	padding-right: 10px
	background: #35b4db
	color: #fff
	text-decoration: none
	border-radius: 5px

.tabla,
.tabla2
	width: 100%

	td
		padding: 10px
		font-size: 13px

.tabla2
	border: 1px solid #edf1f2


.tabla__td
	border: 1px solid #edf1f2

td.tabla__td--textoGrande
	font-size: 14px

.textoGrande
	font-size: 16px


td.tabla__encabezado
	background: #f2f6f7
	font-size: 17px

	strong
		color: #35b4db

td.tabla__encabezado2
	background: #f2f6f7
	font-size: 16px

	strong
		color: #35b4db


// strong
.tabla__titulo
	font-size: 15px
	color: #151616

// strong
.tabla__titulo2
	font-size: 14px
	color: #151616

.tabla__td2
	border-top: 1px solid #edf1f2
	border-right: 1px solid #edf1f2
	border-bottom: 1px solid #fff
	border-left: 1px solid #fff

.tabla__td3
	border-top: 1px solid #fff
	border-right: 1px solid #edf1f2
	border-bottom: 1px solid #fff
	border-left: 1px solid #fff

.importante
	color: #ca4545

.resaltar
	color: #151616

.resaltar2
	font-size: 18px
	color: #151616


.centrar
	text-align: center
