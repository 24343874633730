/*-----------------------------------------------------------*\
	$GLOBAL
    
	Carga todos los recursos requeridos y los compila en un
	único archivo `global.min.css`
\*-----------------------------------------------------------*/



/**
 *
 * Bases:
 *
 * Contiene los archivos necesarios para el correcto
 * funcionamiento de los Layouts, Components, Pages, etc...
 *
 */
@import	'nib'
@import	'base/config'
@import	'base/mixins'
@import 'base/reset'



/**
 *
 * Layouts: Global
 *
 * Contiene estilos que se usaran en todas las páginas
 *
 */
@import	'layouts/header'
@import	'layouts/footer'
@import	'layouts/main'



/**
 *
 * Layouts: Pages
 *
 * Estilos para páginas específicas
 *
 */
@import	'layouts/home'
@import	'layouts/contact'




