/*-----------------------------------------------------------*\
	$RESET
\*-----------------------------------------------------------*/

	
/**
 *
 * Reiniciar las propiedades de algunas etiquetas que
 * por defecto varian en cada navegador.
 *
 */

body
	margin: 0
	padding: 0

	

